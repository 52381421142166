$black:        hsl(0, 0%, 4%) !default;
$black-bis:    hsl(0, 0%, 7%) !default;
$black-ter:    hsl(0, 0%, 14%) !default;

$grey-darker:  hsl(0, 0%, 21%) !default;
$grey-dark:    hsl(0, 0%, 29%) !default;
$grey:         hsl(0, 0%, 48%) !default;
$grey-light:   hsl(0, 0%, 71%) !default;
$grey-lighter: hsl(0, 0%, 86%) !default;
$grey-very-light: hsl(0, 0%, 95%) !default;

$white-ter:    hsl(0, 0%, 96%) !default;
$white-bis:    hsl(0, 0%, 98%) !default;
$white:        hsl(0, 0%, 100%) !default;

$orange:       hsl(14,  100%, 53%) !default;
$blue:         hsl(217, 71%,  53%) !default;
$purple:       hsl(271, 100%, 71%) !default;
$turquoise:    #009fe3;
$green-light:  hsl(171, 100%, 41%) !default;
$green:        hsl(76, 72%, 44%);
$green-dark:   hsl(75, 72%, 34%);
$green-darker: #5c7513;
$yellow:       hsl(39, 70%, 59%);
$yellow-light:       hsl(48,  100%, 67%) !default;
$red:          hsl(0, 86%, 50%);

$primary: darken($turquoise, 5%) !default;
$success: $green !default;
$warning: $yellow !default;
$danger:  $red !default;

$tablet-width: 768px;
$desktop-width: 1007px;

@mixin on-touch {
  @media screen and (max-width: $desktop-width) {
    @content;
  }
}

@mixin on-desktop {
  @media screen and (min-width: $desktop-width) {
    @content;
  }
}

$family-sans-serif: 'Readex Pro', sans-serif;

// navbar
$navbar-item-color: $primary;
$navbar-item-hover-color: darken($primary, 10%);

@import "~bulma/sass/utilities/functions";
@import "~bulma/sass/utilities/initial-variables";
@import "~bulma/sass/utilities/derived-variables";
@import "~bulma/sass/utilities/mixins";
