/* You can add global styles to this file, and also import other style files */
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/material-design-icons/iconfont/material-icons.css";
@import "../node_modules/@fontsource/readex-pro/index.css";

.email-support-link {
  color: #3273dc !important;
  text-decoration: none !important;
}

vmc-maintenance {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}
.dialog-no-padding .mat-dialog-container {
  padding: 0px;
}
@media screen and (max-width: 1440px) {
    .dialog-responsive {
      height: 90%;
    }
}

@media screen and (min-width: 1441px) {
  .dialog-responsive {
    height: 70%;
  }
}
